/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-while */
import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import PropTypes from 'prop-types';

import layoutContext from '../components/layoutContext';
import { DEFAULT_TITLE, DEFAULT_DESCRIPTION } from '../constants/Languages';
import { GLOBAL_CREDIT } from '../constants/SEOTokens';


import Sidebar from '../components/Common/Sidebar/Sidebar';
import PopularPages from '../components/Common/PopularPages/PopularPages';
import Cities from '../components/Common/Cities/Cities';
import Slider from '../components/Slider/Slider';
import Voting from '../components/Common/Voting/Voting';

const StockPost = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, htmlAst } = markdownRemark;
  const {
    title,
    lang,
    path,
    desc,
    headLine,
    canonicalUrl,
    disableTitlePostfix,
    disableDescriptionPostfix 
  } = frontmatter;
  useContext(layoutContext).setCult(lang);

  function* processData(Ast) {
    if (!Ast) {
      return;
    }

    for (let i = 0; i < Ast.length; i++) {
      const node = Ast[i];
      yield node.tagName;

      if (node.children) {
        yield* processData(node.children);
      }
    }
  }

  const it = processData(htmlAst.children);
  let res = it.next();

  for (; ;) {
    if (res.value === 'h1' || res.done) {
      break;
    }
    res = it.next();
  }

  const postfix = disableDescriptionPostfix ? '' : ` | ${DEFAULT_DESCRIPTION[lang]}`;

  const metaTitle = title ? title : `${headLine} | ${DEFAULT_TITLE[lang]}`;
  const metaDescription = desc ? desc :
    `${GLOBAL_CREDIT} - ${headLine}${postfix}`;
  return (
    <div>
      <Seo
        title={metaTitle}
        rating={frontmatter.rating ? frontmatter.rating : 4.5}
        path={path}
        lang={lang}
        description={metaDescription}
        canonicalUrl={canonicalUrl}
        disableTitlePostfix={disableTitlePostfix}
      />
      <Slider />
      <div className='article-container'>
        <div className='article'>
          {!!res.value === 'h1' && <h1>{headLine}</h1>}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Voting
            rating={frontmatter.rating ? frontmatter.rating : 4.5}
            path={path}
          />
        </div>
        <Sidebar
          stocks news
          articles
        />
      </div>
      <PopularPages />
      <Cities />
    </div>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      htmlAst
      frontmatter {
        title
        headLine
        path
        category
        lang
        rating
        desc
        canonicalUrl
        disableTitlePostfix
        disableDescriptionPostfix
      }
    }
  }
`;

export default StockPost;


StockPost.propTypes = {
  data: PropTypes.object
};

